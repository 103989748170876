<template>
  <section class="section section-standalone" id="iku">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between pl-2">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            Indikator Kinerja Utama
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
            >
              <option>TRIWULAN</option>
            </select>
            <select
              class="form-control form-control-sm mr-2 form-control-custom"
            >
              <option>2019</option>
            </select>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-md-5 align-items-stretch">
            <div class="chart-doughnut--wrapper mt-5 mb-4">
              <canvas id="iku-chart" width="400" height="200"></canvas>
              <div class="chart-doughnut--text">
                <h2
                  class="mb-1 secondary-font text-size-large font-weight-bold"
                >
                  174
                </h2>
                <h6
                  class="mb-0 secondary-font text-uppercase text-size-small font-weight-normal"
                >
                  Indikator
                </h6>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div
                  class="card-dashboard border-radius-style rounded-lg p-3 mb-3 bg-green-new"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--success">
                      <img
                        src="@/assets/img/icon/icon-rate-green.svg"
                        alt=""
                        width="50"
                      />
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-green d-flex align-items-center justify-content-end text-success"
                      >
                        74
                      </h4>
                      <p
                        class="mb-0 text-size-small text-uppercase font-weight-normal"
                      >
                        Tercapai
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-10">
                <div
                  class="card-dashboard bg-yellow-new border-radius-style rounded-lg p-3 mb-3"
                >
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--gray">
                      <img
                        src="@/assets/img/icon/icon-rate.svg"
                        alt=""
                        width="50"
                      />
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-warning d-flex align-items-center justify-content-end"
                      >
                        100
                      </h4>
                      <p
                        class="mb-0 text-size-small text-uppercase font-weight-normal"
                      >
                        Belum Tercapai
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-7 align-items-stretch">
            <div
              class="card-dashboard rounded border border-radius-style bg-white"
            >
              <div class="py-3 pl-3 pr-4">
                <h4
                  class="mb-0 text-size-large titilium-font font-weight-normal"
                >
                  Jumlah IKU per SKPD
                </h4>
              </div>
              <div class="card-dashboard-content scroll-xl px-3">
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-row mr-3">
                    <div class="rounded-circle border border-success p-2">
                      <img
                        src="@/assets/img/icon/icon-building.svg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="mb-0 text-capitalize text-body text-size-normal"
                      >
                        Bapenda
                      </p>
                      <span
                        class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                        >10</span
                      >
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-dark-blue rounded-right"
                        role="progressbar"
                        style="width: 50%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        style="width: 50%"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-row mr-3">
                    <div class="rounded-circle border border-success p-2">
                      <img
                        src="@/assets/img/icon/icon-building.svg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="mb-0 text-capitalize text-body text-size-normal"
                      >
                        Sekretariat daerah
                      </p>
                      <span
                        class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                        >10</span
                      >
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        style="width: 80%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-warning rounded-right"
                        role="progressbar"
                        style="width:20%"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-row mr-3">
                    <div class="rounded-circle border border-success p-2">
                      <img
                        src="@/assets/img/icon/icon-building.svg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="mb-0 text-capitalize text-body text-size-normal"
                      >
                        Dinas koperasi
                      </p>
                      <span
                        class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                        >10</span
                      >
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-orange rounded-right"
                        role="progressbar"
                        style="width: 20%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        style="width: 60%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-warning rounded-right"
                        role="progressbar"
                        style="width:20%"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-row mr-3">
                    <div class="rounded-circle border border-success p-2">
                      <img
                        src="@/assets/img/icon/icon-building.svg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="mb-0 text-capitalize text-body text-size-normal"
                      >
                        Dinas pertanian
                      </p>
                      <span
                        class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                        >10</span
                      >
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-dark-blue rounded-right"
                        role="progressbar"
                        style="width: 20%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        style="width: 60%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-warning rounded-right"
                        role="progressbar"
                        style="width:20%"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-row mr-3">
                    <div class="rounded-circle border border-success p-2">
                      <img
                        src="@/assets/img/icon/icon-building.svg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="mb-0 text-capitalize text-body text-size-normal"
                      >
                        Dinas pertanian
                      </p>
                      <span
                        class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                        >10</span
                      >
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-dark-blue rounded-right"
                        role="progressbar"
                        style="width: 20%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        style="width: 60%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-warning rounded-right"
                        role="progressbar"
                        style="width:20%"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-3">
                  <div class="flex-row mr-3">
                    <div class="rounded-circle border border-success p-2">
                      <img
                        src="@/assets/img/icon/icon-building.svg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="mb-0 text-capitalize text-body text-size-normal"
                      >
                        Dinas pertanian
                      </p>
                      <span
                        class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                        >10</span
                      >
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-dark-blue rounded-right"
                        role="progressbar"
                        style="width: 20%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        style="width: 60%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-warning rounded-right"
                        role="progressbar"
                        style="width:20%"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center mb-0">
                  <div class="flex-row mr-3">
                    <div class="rounded-circle border border-success p-2">
                      <img
                        src="@/assets/img/icon/icon-building.svg"
                        alt=""
                        class="img-responsive"
                      />
                    </div>
                  </div>
                  <div class="flex-row w-100">
                    <div
                      class="progress-lable d-flex align-items-center justify-content-between mb-1"
                    >
                      <p
                        class="mb-0 text-capitalize text-body text-size-normal"
                      >
                        Dinas pertanian
                      </p>
                      <span
                        class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                        >10</span
                      >
                    </div>
                    <div class="progress" style="height: 6px;">
                      <div
                        class="progress-bar bg-dark-blue rounded-right"
                        role="progressbar"
                        style="width: 20%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-success rounded-right"
                        role="progressbar"
                        style="width: 60%"
                        aria-valuenow="15"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                      <div
                        class="progress-bar bg-warning rounded-right"
                        role="progressbar"
                        style="width:20%"
                        aria-valuenow="30"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div class="loadmore mt-3 mb-3 d-flex justify-content-center">
                  <button class="btn btn-loadmore">
                    LOAD MORE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
let date = new Date(window.date_now);
export default {
  data() {
    return {
      chart: {
        datasets: [
          {
            data: [60, 30, 10],
            backgroundColor: ['#01a7e5', '#17c12b', '#cbcbcb']
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      indikator_tercapai: 0,
      indikator_belum_tercapai: 0,
      total_indikator: 0,
      ikuPerSkpd: []
    };
  },
  mounted() {
    this.LoadChart();
  },

  methods: {
    LoadChart() {
      window.axios
        .post(window.base_api + 'page/iku', { tahun: date.getFullYear() })
        .then(response => {
          if (response.status == 200) {
            this.chart.datasets[0].data = [
              parseInt(response.data.indikator_tercapai),
              parseInt(response.data.indikator_belum_tercapai)
            ];
            this.setChart();
            this.ikuPerSkpd = response.data.IKUperSKPD;
            this.indikator_tercapai = response.data.indikator_tercapai;
            this.indikator_belum_tercapai =
              response.data.indikator_belum_tercapai;
            this.total_indikator = response.data.total_indikator;
          }
        });
    },
    setChart() {
      var ctx = document.getElementById('ikuChart').getContext('2d');
      let chartData = {
        datasets: this.chart.datasets
      };

      new window.Chart(ctx, {
        type: this.chart.type,
        data: chartData,
        options: this.chart.options
      });
    }
  }
};
</script>

<style></style>
